<mat-toolbar>
  <div id="menu-and-logo">
      <button *ngIf="displaySidenavToggle" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
      </button>
      <button mat-button routerLink="/">
          <img id="logo" src="/assets/images/logos/imsal/Logo_IMSAL-website.png">
      </button>
  </div>

  <mat-nav-list class="nav hide-on-phone">
    <a mat-list-item [routerLink]="'/about'"><div class="header-link">About</div></a>
    <a mat-list-item [routerLink]="'/events'"><div class="header-link">Events</div></a>
    <a mat-list-item [routerLink]="'/islamic-living/prayertimes'"><div class="header-link">Islamic living</div></a>
    <a mat-list-item [routerLink]="'/contact'"><div class="header-link">Contact us</div></a>
    <a mat-list-item [routerLink]="'/faq'"><div class="header-link">FAQ</div></a>
  </mat-nav-list>

  <div id="right-side">
    <mat-nav-list class="nav hide-on-phone">
      <mat-divider class="vertical-divider" vertical="true"></mat-divider>
      <a class="members-link" mat-list-item [routerLink]="'/members'"><div class="header-link">Members</div></a>
    </mat-nav-list>
  
    <div id="icons">
        <a mat-icon-button [routerLink]="'/login'">
            <mat-icon>person</mat-icon>
        </a>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a mat-list-item (click)="sidenav.close()" [routerLink]="'/'"><div class="header-link">Home page</div></a>
      <a mat-list-item (click)="sidenav.close()" [routerLink]="'/about'"><div class="header-link">About</div></a>
      <a mat-list-item (click)="sidenav.close()" [routerLink]="'/events'"><div class="header-link">Events</div></a>
      <a mat-list-item (click)="sidenav.close()" [routerLink]="'/islamic-living/prayertimes'"><div class="header-link">Islamic living</div></a>
      <a mat-list-item (click)="sidenav.close()" [routerLink]="'/contact'"><div class="header-link">Contact us</div></a>
      <a mat-list-item (click)="sidenav.close()" [routerLink]="'/faq'"><div class="header-link">FAQ</div></a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div id="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>