<div id="content-wrap">

  <div id="login">
    <mat-card>
      <mat-card-title><p>Login</p></mat-card-title>
      <mat-card-content>
        <form>
          <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput type="text">
          </mat-form-field>
          
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput type="password">
          </mat-form-field>
          
          <button mat-raised-button color="primary">Login</button>
        </form>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <p>Not a member yet? <a routerLink="/members">Register here</a></p>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
    
  </div>
</div>