import { Component } from '@angular/core';

@Component({
  selector: 'app-home-first-section',
  templateUrl: './home-first-section.component.html',
  styleUrls: ['./home-first-section.component.scss'],
})
export class HomeFirstSectionComponent {
  text = `At IMSAL, our vision is to grow both spiritually and intellectually and to build a strong community of dedicated Muslims who will leave behind them an everlasting legacy of positive influence in Belgium.`;
  displayedText = '';
  index = 0;

  constructor() {}

  ngOnInit(): void {
    // this.type();
  }

  type(): void {
    if (this.index < this.text.length) {
      this.displayedText += this.text.charAt(this.index);
      this.index++;
      setTimeout(() => this.type(), 20); // adjust typing speed here
    }
  }
}
