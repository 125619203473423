import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventManagementService } from 'src/app/shared/services/event-management.service';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss'],
})
export class EventEditComponent implements OnInit {
  event!: Event;

  constructor(
    private route: ActivatedRoute,
    private eventManagementService: EventManagementService
  ) {}

  ngOnInit(): void {}
}
