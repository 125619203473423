<a (click)="onSelect()" class="list-group-item clearfix">  <!--TODO: href-->
    <div class="pull-left">
        <h4 class="list-group-item-heading"> {{ event.title }} </h4>
        <p class="list-group-item-text"> {{ event.description }} </p>
    </div>



    <span class="pull-right">
        <img [src]="event.imagePath" alt="{{ event.title }}">
    </span>
</a>