<div class="py-5">
    <div class="row">
      <div class="col-md-6 mx-auto">
        <span class="anchor" id="formEvent"></span>
  
        <!-- form new event -->
        <div class="card rounded-0">
          <div class="card-header">
            <h3 class="mb-0">Edit event!</h3>
          </div>
          <div class="card-body">
            <form class="form">
              <div class="form-group">
                <label>Title</label>
                <!--<input type="text" [(ngModel)]="" name="title" class="form-control rounded-0" required>-->
              </div>
              <div class="form-group">
                <label>Description</label>
                <!--<textarea [(ngModel)]="" name="description" class="form-control rounded-0"  required></textarea>-->
              </div>
              <!--<button type="button" (click)="" class="btn btn-primary float-right">Edit event</button>-->
            </form>
          </div>
        </div>
        <!-- /form new event -->
  
      </div>
    </div>
  </div>
  