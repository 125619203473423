<div class="members">
    <div 
      *ngFor="let balloon of balloons; let i = index" 
      class="balloon"
      [style.--random-top]="balloon.top" 
      [style.--random-left]="balloon.left"
      [style.background]="balloon.color"
      [style.animation-delay.ms]="i * 10">
    </div>
</div>

<div class="centered-div">
  <iframe id="iframe-agile-team" width="960" height="541" src="https://www.youtube.com/embed/vlhhmesQ7MA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
  <h1>Soon you'll be able to become a member and/or a volunteer, in shaa Allah. <br> 
    If you really can't wait, join our team by sending an email: info@imsal.be</h1>
</div>