<div class="article">
    <app-header-waves [title]="'Contact Us!'"></app-header-waves>
    <div class="article-content">
        <div class="article-content-section">
            <h1 style="text-align: center;">
                You'll soon be able to reach us again via our website forms, God willing. <br>
                In the meantime, for inquiries about mosque visits, events, or volunteering, please email us at info@imsal.be. <br>
                We'll get in touch as soon as possible in shaa Allah.
            </h1>

            <br>
            <br>

            <p style="text-align: center;">
                This website is currently under development and far from being done. We are working on various features and fixing some bugs. <br>
                If you have some feedback to share, a bug you want to report or a typo you noticed, please fill in the anonymous form below:
            </p>

            <div id="form-iframe-div">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScLHQKC17JJdTswnKKir1mRdNe9TxteY2J4-Z_Hys0q2Ji8Yg/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
        </div>
    </div>
    <app-layered-waves></app-layered-waves>
</div>