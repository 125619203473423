<div id="sections">
    <app-home-heading></app-home-heading>
    <section (elementInView)="firstSection.type()" class="curve-sections primary-dark">
        <h1 style="width: 100%;">
            <app-home-first-section #firstSection></app-home-first-section>
        </h1>        
    </section>
    <section id="alumni-section" (elementInView)="showSection(1)" class="curve-sections light">
        <h1 class="section-title"
            [class]="sectionVisibility[1].showH1? '':'hidden'" 
            [@slideIn]="sectionVisibility[1].showH1 ? '*' : 'void'">
            What our alumni say
        </h1>
        <p 
            [class]="sectionVisibility[1].showP? '':'hidden'" 
            [@slideIn]="sectionVisibility[1].showP ? '*' : 'void'">
            <iframe id="iframe-alumni" width="960" height="541" src="https://www.youtube.com/embed/QZpC9GDxVYs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
        </p>
    </section>
    <section id="events-section" (elementInView)="showSection(2); showEventPictures()" class="curve-sections">
        <h1 class="section-title"
            [class]="sectionVisibility[2].showH1? '':'hidden'" 
            [@slideIn]="sectionVisibility[2].showH1 ? '*' : 'void'">
            Join some of our popular events!
        </h1>
        
        <div class="event-cards">
            <mat-card 
                *ngFor="let imgInfo of eventImagesInfo" 
                class="event-card"
                [class]="imgInfo.show ? '':'hidden'" 
                [@slideIn]="imgInfo.show ? '*' : 'void'">
                    <img class="event-image"  
                        [src]="imgInfo.url">
                    <mat-card-title>
                        {{imgInfo.title}}
                    </mat-card-title>
            </mat-card>
        </div>
    </section>
</div>
