import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "../../shared/model/user";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    private BASE_URL: string = environment.apiUrl + "/users";

    constructor(private httpClient: HttpClient) {}

    registerUser(newUser: User): Observable<any> {
        return this.httpClient.post<any>(this.BASE_URL + "/register", newUser);
    }

    loginUser(user: User): Observable<any> {
        return this.httpClient.post<any>(this.BASE_URL + "/login", user);
    }

    getCurrentLoggedInUser(): Observable<any> {
        return this.httpClient.get<any>(this.BASE_URL + "/loggedIn");
    }
}
