import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from 'src/app/shared/model/event';
import { EventManagementService } from 'src/app/shared/services/event-management.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit {
  private _events: Event[] = [];
  selectedEvent!: Event;

  constructor(
    private eventManagementService: EventManagementService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.eventManagementService.getAll().subscribe(
      (res) => {
        this._events = res;
        console.log(this._events);
      },
      (err) => console.log(err)
    );
  }

  get events() {
    return this._events;
  }

  redirectToNewEvent() {
    // TODO: Check with permissions whether the current logged in user can access this page
    this.router.navigate(['new'], { relativeTo: this.route });
  }
}
