import { Component, HostListener, OnInit } from "@angular/core";

declare var window: any;

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    displaySidenavToggle = false;

    constructor() {}

    ngOnInit(): void {
        if (window.innerWidth < 700) {
            this.displaySidenavToggle = true;
        } else {
            this.displaySidenavToggle = false;
        }
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        if (window.innerWidth < 700) {
            this.displaySidenavToggle = true;
        } else {
            this.displaySidenavToggle = false;
        }
    }
}
