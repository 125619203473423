<div class="py-5">
  <div class="row">
    <div class="col-md-6 mx-auto">
      <span class="anchor" id="formEvent"></span>

      <!-- form new event -->
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Make a new event!</h3>
        </div>
        <div class="card-body">
          <form class="form" [formGroup]="newEventForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label>Title</label>
              <input type="text" class="form-control rounded-0" formControlName="title" name="title" required>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control rounded-0" formControlName="description" name="description"
                required></textarea>
            </div>
            <button type="submit" class="btn btn-primary float-right">
              Create event
            </button>
          </form>
        </div>
      </div>
      <!-- /form new event -->

    </div>
  </div>
</div>