import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-event-calendar",
    templateUrl: "./event-calendar.component.html",
    styleUrls: ["./event-calendar.component.scss"],
})
export class EventCalendarComponent implements OnInit {
    @ViewChild("calendarIframe", { static: true }) calendarIframe!: ElementRef;

    ngOnInit() {
        this.onResize();
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        const iframe = this.calendarIframe.nativeElement as HTMLIFrameElement;
        const originalSrc = iframe.getAttribute("src");
        const agendaSubstring = "&mode=AGENDA";
        const weekSubstring = "&mode=WEEK";
        if (window.innerWidth < 700) {
            if (originalSrc?.includes(weekSubstring)) {
                iframe.setAttribute(
                    "src",
                    originalSrc.replace(weekSubstring, agendaSubstring)
                );
            }
        } else {
            if (originalSrc?.includes(agendaSubstring)) {
                iframe.setAttribute(
                    "src",
                    originalSrc.replace(agendaSubstring, weekSubstring)
                );
            }
        }
    }

    // TODO full calender setup:
    // /* IN THIS CLASS WE USE FULLCALENDAR.IO
    //     Checkout docs: https://fullcalendar.io/docs
    //     Checkout rrule (for recurrent events): https://fullcalendar.io/docs/rrule-plugin
    // */

    // calendarOptions: CalendarOptions = {
    //   initialView: 'dayGridMonth', // Other options: timeGridWeek, timeGridDay, listWeek
    //   plugins: [dayGridPlugin, interaction, list, rrule, timegrid],
    //   firstDay: 1, // Monday first day
    //   headerToolbar: {
    //     start: 'prev,next today',
    //     center: 'title',
    //     end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    //   },
    //   navLinks: true,
    //   nowIndicator: true,
    //   selectable: true,
    //   loading: () => this.handleLoading(),
    //   eventClick: this.handleEventClick.bind(this),
    //   // TODO: eventMouseEnter and eventMouseLeave to toggle a preview of the event !!!! (same info prop like eventClick)
    //   dateClick: this.handleDateClick.bind(this),
    // };

    // constructor(
    //   private eventsManagementService: EventManagementService,
    //   private router: Router
    // ) {}

    // ngOnInit(): void {
    //   // https://fullcalendar.io/docs/events-json-feed
    //   this.calendarOptions.events =
    //     this.eventsManagementService.getJsonFeedURLForCalendar();

    //   // TODO: Remove this when done:
    //   /*this.calendarOptions.events = [
    //     {
    //       title: 'event 1',
    //       date: '2020-12-01',
    //       id: '1',
    //       rrule: {
    //         freq: 'weekly',
    //         interval: 5,
    //         byweekday: ['MO', 'FR'],
    //         dtstart: '2020-12-01T10:30:00', // will also accept '20120201T103000'
    //         until: '2021-06-01', // will also accept '20120201'
    //       },
    //     },
    //     { title: 'event 1', date: '2020-12-02', id: '2' },
    //   ];*/
    // }

    // handleDateClick(arg: { dateStr: string }) {
    //   alert('date click! ' + arg.dateStr);
    // }

    // handleEventClick(info: any) {
    //   let eventId: Number = info.event.id;
    //   let eventUrl: String = info.event.url;
    //   console.log(info.event);
    //   // TODO
    // }

    // handleLoading() {
    //   console.log('loading...');
    //   // TODO: figure out how to enable loading per month ! (to avoid loading every single event)

    //   // TODO
    // }
}
