<div class="row">
    <div class="col-md-5">
        <p *ngIf="events.length == 0; else listEvents">No events created!</p>
        <ng-template #listEvents>
            <ul>
                <app-event-item 
                    *ngFor="let event of events" 
                    [event]="event" 
                    (selectedEventEmitter)="selectedEvent = $event">
                </app-event-item>
            </ul>
        </ng-template>
    </div>
    <div class="col-md-7">
        <app-event-zoom *ngIf="selectedEvent" [event]="selectedEvent"></app-event-zoom>
    </div>
</div>

<hr>

<div class="row">
    <button (click)="redirectToNewEvent()" class="btn btn-success">New Event</button>
</div>
