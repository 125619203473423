<table class="prayer-day-table">
    <thead>
        <tr>
            <th></th>
            <th>Adhan</th>
            <th>Iqama</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Fajr</td>
            <td>3:17</td>
            <td>4:30</td>
        </tr>
        <tr>
            <td>Sunrise</td>
            <td>5:30</td>
            <td>-</td>
        </tr>
        <tr>
            <td>Dhohr</td>
            <td>13:41</td>
            <td>13:51</td>
        </tr>
        <tr class="next-prayer">
            <td>Asr</td>
            <td>18:01</td>
            <td>18:11</td>
        </tr>
        <tr>
            <td>Maghreb</td>
            <td>21:53</td>
            <td>22:03</td>
        </tr>
        <tr>
            <td>Isha</td>
            <td>23:52</td>
            <td>23:52</td>
        </tr>
    </tbody>
</table>