import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from './abstract-base.service';
import { Event } from '../model/event';

@Injectable({
  providedIn: 'root',
})
export class EventManagementService extends AbstractBaseService<Event> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.URL += '/events';
  }

  getJsonFeedURLForCalendar(): string {
    return this.URL + '/calendar-feed';
  }
}
