<!-- <full-calendar class="entry-content" [options]="calendarOptions"></full-calendar> -->

<div class="center-div">
    <h2>Make sure to register by clicking on the event!</h2>

    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%235e831f&ctz=Europe%2FBrussels&showTitle=0&showNav=1&showPrint=0&showTz=0&src=Y18wMzJiZWZkZjdiNTk5ZWM3NjFlMTViYzM4MTdkYjZjMmQ1OTg0ZTRhMjZlMDNiOTgzYTkxZTMzOTE2ODEwMDFkQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%230B8043&mode=WEEK" 
            style="border-width:0" 
            width="800" 
            height="600" frameborder="0" scrolling="no" 
            #calendarIframe>
    </iframe>
</div>