import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[elementInView]',
})
export class ElementInViewDirective {
  @Output() public elementInView: EventEmitter<any> = new EventEmitter();
  private hasBeenInView: boolean = false;

  constructor(private element: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    const elementPosition =
      this.element.nativeElement.getBoundingClientRect().top + window.scrollY;
    const scrollPosition = window.scrollY + window.innerHeight;

    if (scrollPosition > elementPosition && !this.hasBeenInView) {
      this.elementInView.emit();
      this.hasBeenInView = true;
    }
  }
}
