import { Component, Input } from "@angular/core";

@Component({
    selector: "app-header-waves",
    templateUrl: "./header-waves.component.html",
    styleUrls: ["./header-waves.component.scss"],
})
export class HeaderWavesComponent {
    @Input()
    title?: string;
}
