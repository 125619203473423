import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ofCategory'
})
export class OfCategoryPipe implements PipeTransform {
  transform(values: any[], label: string): any[] {
    return values.filter(v => v.role === label);
  }
}
