import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MembersComponent } from "./members/members.component";
import { LoginComponent } from "./authentication/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EventsMakerComponent } from "src/app/events/events-maker/events-maker.component";
import { EventDetailComponent } from "src/app/events/event-detail/event-detail.component";
import { EventItemComponent } from "src/app/events/event-list/event-item/event-item.component";
import { EventEditComponent } from "src/app/events/event-edit/event-edit.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { EventsComponent } from "src/app/events/events.component";
import { EventListComponent } from "src/app/events/event-list/event-list.component";
import { EventZoomComponent } from "src/app/events/event-list/event-zoom/event-zoom.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";

import { EventCalendarComponent } from "src/app/events/event-calendar/event-calendar.component";
import { HeaderComponent } from "./header/header.component";
import { HomeComponent } from "./home/home.component";

import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PrayertimesDayTableComponent } from "./prayer/prayertimes-day-table/prayertimes-day-table.component";
import { FooterComponent } from "./footer/footer.component";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { UserDialogComponent } from "./user-dialog/user-dialog.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TruncatePipePipe } from "./truncate-pipe.pipe";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDividerModule } from "@angular/material/divider";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";

import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { OfCategoryPipe } from "./of-category.pipe";
import { HomeHeadingComponent } from "./home/home-heading/home-heading.component";
import { MembersListComponent } from "./members/members-list/members-list.component";
import { HomeFirstSectionComponent } from "./home/home-first-section/home-first-section.component";
import { ElementInViewDirective } from "./shared/directives/element-in-view.directive";
import { CarouselComponent } from "./carousel/carousel.component";
import { AboutPageComponent } from "./about-page/about-page.component";
import { LayeredWavesComponent } from "./layered-waves/layered-waves.component";
import { HeaderWavesComponent } from "./header-waves/header-waves.component";
import { ContactUsPageComponent } from "./contact-us-page/contact-us-page.component";
import { FaqPageComponent } from "./faq-page/faq-page.component";
import { PrayerTimesComponent } from './prayer/prayer-times/prayer-times.component';

// FullCalendarModule.registerPlugins([
//   // FullCalendar plugins
//   dayGridPlugin,
//   timeGridPlugin,
//   listPlugin,
//   interactionPlugin,
//   rrulePlugin,
// ]);
@NgModule({
    declarations: [
        TruncatePipePipe,
        AppComponent,
        MembersComponent,
        LoginComponent,
        EventsMakerComponent,
        EventDetailComponent,
        EventItemComponent,
        EventEditComponent,
        PageNotFoundComponent,
        EventsComponent,
        EventListComponent,
        EventZoomComponent,
        EventCalendarComponent,
        HeaderComponent,
        HomeComponent,
        OfCategoryPipe,
        PrayertimesDayTableComponent,
        FooterComponent,
        UserDialogComponent,
        TruncatePipePipe,
        OfCategoryPipe,
        HomeHeadingComponent,
        MembersListComponent,
        HomeFirstSectionComponent,
        ElementInViewDirective,
        CarouselComponent,
        AboutPageComponent,
        LayeredWavesComponent,
        HeaderWavesComponent,
        ContactUsPageComponent,
        FaqPageComponent,
        PrayerTimesComponent,
    ],
    imports: [
        MatTabsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        RouterModule,

        // google-photos module
        MatPaginatorModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        // Angular Material
        MatGridListModule,
        MatSelectModule,
        MatCheckboxModule,
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatDividerModule,
        MatSidenavModule,
        MatListModule,
        MatTabsModule,
        MatDialogModule,
        MatProgressBarModule,
        MatExpansionModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
