import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prayertimes-day-table',
  templateUrl: './prayertimes-day-table.component.html',
  styleUrls: ['./prayertimes-day-table.component.scss']
})
export class PrayertimesDayTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
