import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Event } from 'src/app/shared/model/event';
import { EventManagementService } from 'src/app/shared/services/event-management.service';

@Component({
  selector: 'app-events-maker',
  templateUrl: './events-maker.component.html',
  styleUrls: ['./events-maker.component.scss'],
})
export class EventsMakerComponent implements OnInit {
  newEventForm!: UntypedFormGroup;

  constructor(
    private eventsManagementService: EventManagementService,
    private router: Router
  ) {}

  ngOnInit() {
    this.newEventForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null, Validators.required),
    });
  }

  createEvent() {
    // TODO: extra constraints:
    //        * Every event should have a title, start time,

    let event: Event = {
      title: this.newEventForm.value.title,
      description: this.newEventForm.value.description,
      imagePath: '',
    };

    this.eventsManagementService.createOrUpdate(event).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  }

  onSubmit() {
    // TODO: verifications
    this.createEvent();
    // TODO: redirections
  }
}
