import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogComponent } from 'src/app/user-dialog/user-dialog.component';
@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
  animations: [
    trigger('onHover', [
      state(
        'mouseOver',
        style({
          transform: 'translateY(-3px)',
          '-webkit-transform': 'translateY(-3px)',
          'box-shadow': '0 5px 18px rgba(0, 0, 0, 0.4)',
        })
      ),
      transition('mouseOver <=> *', animate('0.2s')),
    ]),
  ],
})
export class MembersListComponent implements OnInit {
  gridCards = 3;
  users = [
    {
      name: 'Nadine Tchakounte',
      email: 'nadine@gmail.be',
      gender: 'female',
      occupation: 'developer',
      company: 'freelance',
      degree: 'bachelor',
      campusLocation: 'xxxxxx....',
      phone: '0486xxxxxxxx',
      address: 'rue du campanile...',
      role: 'Board Members',
      about:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum est, quasi rerum ducimus cum voluptatem magni facilis ullam qui tempore illum eius necessitatibus molestiae obcaecati pariatur, fugit earum saepe labore!',
      profile: 'assets/images/profilePics/profile1.webp',
    },
    {
      name: 'testAgile',
      email: 'nadine@gmail.be',
      gender: 'female',
      occupation: 'developer',
      company: 'freelance',
      degree: 'bachelor',
      campusLocation: 'xxxxxx....',
      phone: '0486xxxxxxxx',
      address: 'rue du campanile...',
      role: 'Agile Team',
      about: 'I am a test user ',
      profile: 'assets/images/profilePics/profile.jpg',
    },
    {
      name: 'TestV',
      email: 'nadine@gmail.be',
      gender: 'female',
      occupation: 'developer',
      company: 'freelance',
      degree: 'bachelor',
      campusLocation: 'xxxxxx....',
      phone: '0486xxxxxxxx',
      address: 'rue du campanile...',
      role: 'Volunteers',
      about: 'I am a test user',
      profile: 'assets/images/profilePics/profile.jpg',
    },
    {
      name: 'TestMem',
      email: 'nadine@gmail.be',
      gender: 'female',
      occupation: 'developer',
      company: 'freelance',
      degree: 'bachelor',
      campusLocation: 'xxxxxx....',
      phone: '0486xxxxxxxx',
      address: 'rue du campanile...',
      role: 'Members',
      about: 'I am a test user',
      profile: 'assets/images/profilePics/person.png',
    },
    {
      name: 'TestAgile',
      email: 'nadine@gmail.be',
      gender: 'female',
      occupation: 'developer',
      company: 'freelance',
      degree: 'bachelor',
      campusLocation: 'xxxxxx....',
      phone: '0486xxxxxxxx',
      address: 'rue du campanile...',
      role: 'Agile Team',
      about: 'I am a test user',
      profile: 'assets/images/profilePics/person.png',
    },
  ];
  categories = ['Board Members', 'Agile Team', 'Volunteers', 'Members'];
  constructor(public dialog: MatDialog) {}
  openDialog(data: any) {
    this.dialog.open(UserDialogComponent, {
      data,
      panelClass: 'dialog-container-custom',
    });
  }
  //Angular Animations on hover
  currentState: string[] = [];
  hoverOn(index: any) {
    this.currentState[index] = 'mouseOver';
  }
  hoverOff(index: any) {
    this.currentState[index] = '*';
  }
  ngOnInit(): void {}
}
