<a href="#">
  <ul>
    <li>{{ event.title }} YEPP</li>
  </ul>

</a>

<div class="btn-group">
  <button class="btn btn-success">Edit</button>
  <button class="btn btn-danger">Delete</button>
</div>
