import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseModel } from 'src/app/shared/model/base-model';

export abstract class AbstractBaseService<I extends BaseModel> {
  protected URL: string = 'http://localhost:8080/api/v1';

  constructor(protected httpClient: HttpClient) {}

  getAll(): Observable<I[]> {
    return this.httpClient.get<I[]>(this.URL);
  }

  getById(id: string) {
    return this.httpClient.get<I>(this.URL + '/' + id);
  }

  createOrUpdate(object: I): Observable<I> {
    return this.httpClient.post<I>(this.URL + '/create-update', object);
  }

  deleteById(id: string) {
    return this.httpClient.delete<I>(this.URL + '/' + id);
  }
}
