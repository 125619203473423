<div id="main-wrapper">
  <div id="page-header">
    <app-header></app-header>
  </div>
  <div id="page-container">
    <router-outlet></router-outlet>
  </div>
  <div id="page-footer">
    <app-footer></app-footer>
  </div>
</div>
