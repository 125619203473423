<app-header-waves [title]="'Join some of our upcoming events!'"></app-header-waves>

<h1 style="text-align: center;">We are currently working on this page. It is not yet active.</h1>
<h1 style="text-align: center;">To stay tuned about our latest updates, please follow us on our <a href="https://www.instagram.com/imsal.leuven/" target="_blank">Instagram</a> or <a href="https://www.facebook.com/IMSAL.Belgium/" target="_blank">Facebook page</a>.</h1>

<br>
<br>
<br>
<app-carousel [autoSlide]=true></app-carousel>
<div id="content-wrap">
    <app-event-calendar></app-event-calendar>
    <!-- TODO: <router-outlet></router-outlet> -->
</div>