import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/model/event';

@Component({
  selector: 'app-event-zoom',
  templateUrl: './event-zoom.component.html',
  styleUrls: ['./event-zoom.component.scss'],
})
export class EventZoomComponent implements OnInit {
  @Input()
  event!: Event;

  constructor() {}

  ngOnInit(): void {}

  onToEventDetails() {
    // TODO: find a way to navigate to events/:id
    // ( probably store eventID as property in Event model,
    //   or maybe retrieve the index of the list in event-list without going back to backend )
  }
}
