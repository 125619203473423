<div class="carousel">
    <figure *ngFor="let image of images,let i=index " id="{{'figure'+i}}"
        [ngClass]="{'image-active': selectedIndex === i}">
        <img [src]="image.url"/>
    </figure>

    <div *ngIf="indicators" class="carousel-dot-container">
        <span *ngFor="let dot of images,let i=index" class="dot" [ngClass]="{'active': selectedIndex === i}"
            (click)="clickImage(i)"></span>
    </div>
</div>