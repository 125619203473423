<footer class="w3-center w3-black w3-padding-16">

    <div class="floats">
        <div id="contact-mo">
            <p>
                <span>
                    <a href="https://www.facebook.com/IMSAL.Belgium" target="_blank">
                        <img src="assets/images/social/fb.png" alt="">
                    </a>
                </span>
                <span>
                    <a href="https://twitter.com/imsalbelgium" target="_blank">
                        <img src="assets/images/social/twt.png" alt="">
                    </a></span>
                <span>
                    <a href="https://www.instagram.com/imsal.leuven/" target="_blank">
                        <img src="assets/images/social/instagram.png" alt="">
                    </a></span>
                <span>
                    <a href="https://www.youtube.com/user/imsalleuven" target="_blank">
                        <img src="assets/images/social/yt.png" alt="">
                    </a></span>
                <span>
                    <a href="https://www.flickr.com/photos/77945450@N02/" target="_blank">
                        <img src="assets/images/social/flickr.png" alt="">
                    </a></span>
            </p>
        </div>

        <div id="info">
            <p><a href="#">contact us</a></p>
            <p> <a href="#">privacy policy</a></p>
            <img src="assets/images/logos/imsal/Logo_IMSAL-website.png" alt="">
        </div>
        <div id="copyright">
            <p>
                IMSAL Copyright © 2023 <br>
                Website made in collaboration with De Minaret vzw
            </p>
        </div>
    </div>



</footer>