<div class="article">
    <app-header-waves [title]="'Get to know more about IMSAL'"></app-header-waves>
    <div class="article-content">
        <div class="article-content-section">
            <h1>What is IMSAL?</h1>
            <p>First of all, as-salāmu ‘alaikum, a very warm welcome to Leuven!</p>
            <p>
                IMSAL is a Muslim student organisation run by students in Leuven, Belgium. 
                Our goal is to help you with all your needs as Muslim student during your time here. 
                We will help you get familiar with life in Leuven as a muslim student and integrate in Belgian society. 
                At IMSAL, you can meet students from every corner of the world, although we are also open to non-students. 
                We strive to make this community feel like home. 
            </p>
            <p>
                You can benefit from IMSAL in many different ways, 
                from learning and practising the religion to enjoying yourself with your brothers or sisters. 
                You can also choose to become a volunteer at IMSAL to help other students. 
                IMSAL runs the KU Leuven student mosque in the centre of Leuven and organises 
                a wide range of events and activities throughout the year. 
            </p>
        </div>

        <div class="article-content-section">
            <h1>The student mosque</h1>
            <p>
                The student mosque has congregational prayers 5 times a day, as well as the Jumu’ah prayer on Friday 
                and the prayer on both Eid days. 
                It also has a library for you to find books to study different Islamic sciences. 
                During Ramadan and on Thursdays, we often break our fast together at the mosque, 
                and we perform taraweeh prayers in the mosque as well. 
                Together with all the other social events organised by IMSAL, 
                these occasions help you to be part of a warm and supportive community that nourishes your faith. 
                The mosque remains open between prayers from dhuhr to ‘isha as well. 
            </p>
        </div>

        <div class="article-content-section">
            <h1>Activities</h1>
            <p>
                IMSAL organises several occasional events throughout the year such as study circles to learn Quran, 
                Hadith or the Arabic language, day trips and hikes, open mosque days, potluck dinners, sports and much more. 
                Be sure to check the calendar, subscribe to our newsletter and follow our social media pages to stay updated about our events! 
            </p>
        </div>

        <div class="article-content-section">
            <h1>History</h1>
            <p>
                IMSAL is an independent student association, officially registered with LOKO (KU Leuven) and Leuven City Hall, 
                representing muslim students in Leuven. 
                Founded in 1984, we were established to provide an Islamic meeting space for muslim students, 
                and in response to Allah’s saying:
            </p>
            <p style="text-align: center; font-weight: bold;">وَاعْتَصِمُوا بِحَبْلِ اللَّهِ جَمِيعًا وَلَا تَفَرَّقُوا</p>
            <p style="text-align: center; font-weight: bold;">"Hold fast to the covenant of Allah and do not be disunited"</p>
            <p>
                Established by Indonesian students, IMSAL has come a long way. 
                If you want to know more about the history of the organisation, check out the video on our homepage and our podcast, 
                which has some episodes with senior members and alumni. 
            </p>
        </div>

    </div>
    <app-layered-waves></app-layered-waves>
</div>