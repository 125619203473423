import { EventEmitter, Component, Input, OnInit, Output } from '@angular/core';
import { Event } from 'src/app/shared/model/event';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {
  @Input()
  event!: Event;

  @Output()
  selectedEventEmitter = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  onSelect() {
    this.selectedEventEmitter.emit(this.event);
    console.log(this.event);
  }
}
