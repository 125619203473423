import { Component } from '@angular/core';

@Component({
  selector: 'app-layered-waves',
  templateUrl: './layered-waves.component.html',
  styleUrls: ['./layered-waves.component.scss']
})
export class LayeredWavesComponent {

}
