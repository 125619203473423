<div class="article">
    <app-header-waves [title]="'Check-out our Q&A!'"></app-header-waves>
        <mat-accordion multi>
            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Prayer
                    </mat-panel-title>
                    <mat-panel-description>
                        Prayer rooms and prayer times
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <h1>Prayer rooms</h1>
                <p>
                    KU Leuven has prayer rooms on almost every campus. 
                    You can find a list of the rooms <a href="https://www.kuleuven.be/stuvo/reldiv/stilleruimtes" target="_blank">here</a> 
                </p>
                <p>
                    Most of these are ‘silent rooms’, so we are asked not to leave anything in the room. 
                    Often, there will be cupboards where you can store prayer mats and other objects. 
                    Be sure to leave the rooms in good state and respect others using the rooms. 
                </p>

                <mat-divider></mat-divider>

                <br>
                <h1>Prayer times</h1>
                <p>
                    In the student mosque, we follow the prayer times as agreed upon by the council of scholars 
                    of the Muslim Executive of Belgium (EMB), as many of the mosques in the country do. 
                </p>
                <p>
                    Iqama of Fajr prayer at the mosque is currently exactly 1h before sunrise (chourouq).
                    All other prayers have a fixed delay of 10 min between adhan and iqama.
                    Jumu'a (friday) prayer is fixed at 1pm in Winter time and at 2pm in Summer time.
                </p>
                <p>
                    As mentioned on the first page of EMB’s calendar, starting from 1st of May and until the end of July, 
                    in all countries residing at a latitude angle of 45° and above (Belgium’s latitude is ~51°), 
                    the distinctive signs of Fajr and Isha are troubled and/or can’t be observed. 
                    During this period, the EMB Council of Theologians bases its prayer times of Fajr and Isha on the closest approximation of their signs.
                </p>
                <p>
                    In a 
                    <a href="https://www.e-cfr.org/blog/2017/05/12/%D8%AD%D9%88%D9%84-%D9%85%D9%88%D8%A7%D9%82%D9%8A%D8%AA-%D8%A7%D9%84%D8%B5%D9%84%D8%A7%D8%A9-%D9%88%D8%A7%D9%84%D8%B5%D9%8A%D8%A7%D9%85-%D8%A7%D9%84%D8%A8%D9%84%D8%A7%D8%AF-%D8%B0%D8%A7%D8%AA-%D8%AE/?__cf_chl_jschl_tk__=8756535388cf6571b020ca22a3289028737f4425-1619476269-0-AY8yfzR-Aa0O3r_TPI-L5N0fr72upmOMlo3QHo-_g16PPp2QDHAN_xsuIRhEZ4UPIl-ggDzbHZ2cgzfMwcGJWo6LKy0lqj805sVgQ4kGhoAnZFOg4EsL1gIZRIWhpig2hd5xe-z0a6BE1PHd2sWZLO_5XDCth0No9WXaCViwKrp_66XcCJ98Zu0OfeK185mOI3cXFg18j4PDqMLiHIHrC6WSP6I302rI_ATZrojvOPuXQkdHwZz0ymJX_P-T6cj-xadBWqI3n0QTRLAXnFGNyQHkECfjLOuhhhORqwJI9NDJ0CPyr4z3p_DH_wECvKJf-qkDyBTadVd3-Qvno1vDU5wF6ichd4YZIrGVWRudvCsNzOaS30ufrlQ9P7pO4-OsOw-d750LGUBNfmhwQk2I0V2JQCVySgtEniRP61P-Eza14WFOyUPQ8oubNOU12V0ZRBOxWYiqupBvr86XKxldgMGE9Txu8nUTpNzQaDPxcIkA0hsckcqRNY_hVmyRHDPhyJR6HjegXsmQJnZebbMIachSFuFhsQ2ooQyksvZqm_u71VmrTVWUNmvoGmTz5NYxt5Vmf1bAdp7mxrXNxOAPD8RGL-we_W2-DQrPkkrRBccAwaShw51xkWZa-63kgt8l3enPHSeUNF7HbuhcovZM3kqDKQxl12NZ2cpU5k9dmS8c&fbclid=IwAR2I8Qh925o7-qfH7HelJulVDl-J6yTQJCSZs7e1FO27m64AIB9bbwWtHGo" target="_blank">
                        fatwa
                    </a> issued in 2004 by the European Council For Fatwa And Research, 
                    3 different rulings have been expressed for these countries depending on how high the latitude angle is. 
                    Belgium is categorized in the 2nd zone which is between 48° and 66°.
                </p>
                <p>
                    Based on EMB’s recommendation, it is allowed during this period from May until the end of July to group the prayers of Maghreb and Isha for anyone who is subject to embarrassment and difficulty. 
                    However, what is important to note here is their explicit claim that this is done “رفعا للحرج” as a way of lifting up embarrassment/difficulty from the community. 
                    Therefore, as this does not apply to all individuals (like those who intend and can stay awake until late in the night), we will not be applying this authorized “relaxation” on a mosque level.
                </p>
                <p>
                    Questions on this matter can be forwarded to EMB’s council of theologians by sending a mail to conseil.theologiens@embnet.be or by calling them at 02 210 02 37.
                </p>
                
            </mat-expansion-panel>

            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Interested in Islam
                    </mat-panel-title>
                    <mat-panel-description>
                        Want to revert to Islam or already a revert?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                    Do you want to know more about Islamic beliefs and practices, or are you already a revert and you need help? 
                    You came at the right place! Many of our members are reverts! 
                </p>
                <p>
                    We are currently working on some publications with basic information about our religion and a list of resources, as well as on a buddy-program. 
                    For the moment, you can always contact us, or come to some of our events to speak to people, if you like to know more.
                </p>
                <h1>I want to become Muslim</h1>
                <p>
                    Are you convinced Islam is the truth, but you’re not sure how you can become Muslim? Don’t worry, it doesn’t take much. 
                    If you contact us at info@imsal.be, we can forward you to the right people to help you with everything. 
                    It doesn’t stop there though. After converting, we can put you in touch with people who can help you with everything 
                    -from learning how to pray and the basic creed to putting you in touch with your brothers or sisters in faith. 
                </p>

                <mat-divider></mat-divider>

                <br>
                <h1>I have become Muslim…. What now?</h1>
                <p>
                    After becoming Muslim, you might face some challenges. How do I pray? How do I feel the connection with my Lord? 
                    Am I reciting the Qur’an correctly? Where do I go with questions? 
                    If you contact us, we can put you in touch with someone who can help you with all your questions, in sha’Allah. 
                </p>
                
            </mat-expansion-panel>
            
            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Food
                    </mat-panel-title>
                    <mat-panel-description>
                        Dining out and where to buy halal food items
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <h1>Dining out</h1>
                <p>
                    Sadly there is not a wide variety of halal options for dining outside. 
                    There are a few restaurants that declare that their food is sourced and prepared in halal manner.
                </p>
                <p>
                    We are working on a list of these restaurants for your easy reference. 
                    In the meantime, you can always ask people in the mosque for recommendations. 
                </p>
                <p>Here are also a few general things to keep in mind when dining out:</p>
                <ol>
                    <li>As a western European country, alcohol is omnipresent in Belgium. Very few restaurants run without serving alcohol. Dine at your own discretion.</li>
                    <li>To know if the food served at a restaurant is halal, As a general rule, you can look if the website or front posters of the restaurant mentions whether their food is halal.</li>
                    <li>Though it may seem reasonable to assume that kebab shops are halal, not all products sold at the kebab shops (for eg:- several types of processed meat ready-to-fry products) are always halal. 
                        You may have to ask the owner or err on the side of caution and avoid these products.</li>
                    <li>Another important note about Belgium: Belgium is famous for potato fries. 
                        However, the recipe involves frying the potatoes in animal fat. Hence, not all potato fries are vegetarian as you may assume. 
                        Here, as well, it’s better to ask the owner. 
                    </li>
                </ol>

                <mat-divider></mat-divider>
                <br>
                <h1>To buy halal food items for food preparation</h1>
                <p>Leuven has some butchers selling halal meat and poultry products. A few are listed below:</p>
                <ol>
                    <!-- TODO: clickable links -->
                    <li><a href="https://maps.app.goo.gl/1tWrypZvnUZtHxss7" target="_blank">Al Taj</a></li>
                    <li><a href="https://maps.app.goo.gl/pAjix5JTFc5GsbEt9" target="_blank">Beenhowerij Mediteranee (butcher)</a></li>
                    <li><a href="https://maps.app.goo.gl/6KynBc4HhCu3i9FP7?g_st=ic" target="_blank">Leuvense slagerij (butcher)</a></li>
                    <li><a href="https://maps.app.goo.gl/Fjekc6hhxQxm4ti96?g_st=ic" target="_blank">Fontein slagerij (butcher)</a></li>
                </ol>
            </mat-expansion-panel>

            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Housing
                    </mat-panel-title>
                    <mat-panel-description>
                        The kotlabel, how to find, what to look out for
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <h1>Student housing in Leuven</h1>
                <p>
                    Leuven is a university town. A lot of buildings and apartments are maintained just to rent out for the students. 
                    First of all, Dormitories of several students in a common space for sleeping or student hostels with a common organised mess dining etc. 
                    are not commonly available in Leuven. The most common and cheap way to live is to rent a student room, or ‘kot’ as locals call it. 
                    These are relatively expensive if you choose to rent a studio, with a private kitchenette and bathroom/toilet inside the room. 
                    Most student rooms don’t have these private facilities, but share a kitchen and bathroom with other students. 
                    Another viable solution is to rent an apartment or house with multiple rooms together with other students. 
                    Now, where can you find the accommodation you’re looking for? 
                </p>
                <p>Before we share some resources, first some warnings and things to look out for:</p>
                <ul>
                    <li>
                        There are a lot of scammers trying to take advantage of newcomers. 
                        If some ads look too good to be true (like a fantastic apartment at a very cheap price), 
                        they most likely are. 
                    </li>
                    <li>
                        KU Leuven has a ‘kotlabel’, which guarantees the quality of student housing. 
                        Look for a white square with a blue ‘K’ on the facade of the building.  
                    </li>
                    <li>
                        If possible, try to check out the room before signing the contract. 
                        Pay attention to the cleanliness, insolation (it gets cold in winter!), 
                    </li>
                    <li>
                        You don’t have to pay any advances to reserve a house for you. 
                        There’s a deposit you pay once at the beginning, and get back if everything is as it was when you moved in after your contract finishes. 
                        After that you pay rent and fixed costs for maintenance, and if applicable an advance for the utilities (gas, electricity, water and internet). 
                    </li>
                    <li>
                        Be careful not to turn the heating on too much. We know some of you come from countries where the temperatures are a little more tropical, 
                        but heating usually works on gas, and will cost a lot if you keep it on max all the time!
                    </li>
                </ul>
                <mat-divider></mat-divider>
                <br>
                <h1>Finding student housing</h1>
                <p>There are several ways to find student housing:</p>
                <h2>A- Kotwijs</h2>
                <p>
                    The university  <a href="https://www.kuleuven.be/english/life-at-ku-leuven/housing/find-housing/students" target="_blank">KU Leuven has a website</a> 
                    which contains a lot of useful information on finding student housing as well as a link to Kotwijs. 
                    This is a platform where KU Leuven allows recognized landlords to publish ads. 
                    You can filter for your needs and find apartments or studios and contact them. 
                </p>
                <h2>B- Private websites</h2>
                <p>
                    There are several private websites and platforms where landlords publish advertisements, such as www.immoweb.be , www.zimmo.be , and others.
                </p>
                <p>
                    Do not transfer money immediately without doing due diligence!
                </p>
                <h2>C- Facebook groups</h2>
                <p>
                    Facebook can be very helpful to find apartments/studios. There are few popular facebook pages/groups were ads appear regularly. 
                    If some ads look too good to be true (like a fantastic apartment at a very cheap price) they most likely are, so be aware of scammers. 
                </p>
                <ul>
                    <li><a href="https://www.facebook.com/groups/357365197757461/" target="_blank">Te huur: kot in Leuven</a></li>
                    <li><a href="https://www.facebook.com/groups/RoomInfo/" target="_blank">Room information Leuven</a></li>
                    <li><a href="https://www.facebook.com/groups/1631204050459112/" target="_blank">Short-term rent/sublet in Leuven</a></li>
                </ul>
                <h2>D- KU Leuven's residence halls</h2>
                <p>
                    These are studio/apartment complexes managed by the university itself. You can find all the details on the 
                    <a href="https://www.kuleuven.be/english/life-at-ku-leuven/housing/find-housing/students/residences" target="_blank">website</a>. 
                    They are usually in high demand.
                </p>
                <mat-divider></mat-divider>
                <br>
                <h1>Housing costs</h1>
                <p>
                    Make sure what you are getting into in terms of monthly expenses. Your rent is only a part. Rent, often, does not include:
                </p>
                <ul>
                    <li>
                        Cost for electricity, water, gas (for heating, some buildings are heated using electricity). Electricity cost has been rising heavily due to the prevailing geopolitical situation. 
                    </li>
                    <li>
                        General building maintenance
                    </li>
                    <li>
                        Heating (you cannot survive European winter without heating). This is often overlooked by students who might come from places where it’s warm all year round.
                         Heating costs can rise to very high amounts if you keep your heating on all the time - be careful! 
                    </li>
                    <li>
                        Internet. In some residences it is part of the package, especially at residences managed by KU Leuven. Otherwise, you have to subscribe yourself. 
                        Some providers have student packages, be sure to ask for information. 
                    </li>
                    <li>
                        Some residence halls provide washing machines in the building as a cheaper option for laundry. If you were to go to a shop, washing + drying could cost up to 5.5 euros.
                    </li>
                </ul>
                <p>
                    Make sure you consider all these costs before you decide what ‘rent’ you can afford. However, many student residence halls, especially those managed by the university, 
                    provide an all inclusive rent (heating/water/electricity +/- internet). So do clarify this with the landlord.
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Administration
                    </mat-panel-title>
                    <mat-panel-description>
                        Applying visa, residence permit, seeking help
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                    KU Leuven’s website has some page where all information on the administrative requirements and procedures for international students can be found. 
                    Consult <a href="https://www.kuleuven.be/english/prospective-students/welcome-international-students" target="_blank">this page</a> 
                    and
                    <a href="https://www.kuleuven.be/english/life-at-ku-leuven/immigration-residence" target="_blank">this page</a> 
                    for all your questions. 
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Sadaqa and zakah
                    </mat-panel-title>
                    <mat-panel-description>
                        Where to donate? I'm in need of donations
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                    IMSAL currently does not accept Zakah. We do accept sadaqa for various purposes. Please go to the donate section for more information. 
                    Be sure to mention the purpose of your donation! 
                </p>
                <p>
                    For Zakah, you might want to consider this organizations: <a href="https://karama-solidarity.be/nl/page-projet-zakaat-al-maal-2022-nl/" target="_blank">Karama Solidarity</a>
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Leisure
                    </mat-panel-title>
                    <mat-panel-description>
                        How can I spend my free time? Where can I have fun?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                    Whether you’re new to Belgium or have been living here for a long time, finding appropriate ways to spend your leisure time is not always easy. 
                    IMSAL is always trying to fill this gap. Often, groups of members get together to play football, basketball, practice wrestling, and much more. 
                    Time to time, you can expect activities like hikes, potlucks or barbecues to pop up. Keep an eye on our social media to stay updated on activities, 
                    and don’t hesitate to ask in our Facebook group if people are playing your favourite sport, or interested to gather for other hobbies and activities.
                </p>
                <p>
                    Do you want to organise a leisure activity? That’s great news! Contact us at info@imsal.be, so we can help you with the practicalities. 
                    Before you know it, you may start a new successful event! 
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel class="custom-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Medicine and health care
                    </mat-panel-title>
                    <mat-panel-description>
                        Mutualiteit, emergencies, pharmacy
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                    The information here is for general guidance only. 
                </p>
                <p>
                    In most cases, you are registered to a mutualiteit a few days after your arrival (eg : CM, FSMB, LM,...). 
                    In Belgium, when you are sick, the first contact point is your  GP (General Practitioner) unless it is an emergency. 
                    On your first visit to a GP, you can request to register this particular GP as your ‘family physician’. 
                    Once you consult the GP and pay the invoice you can submit the invoice to your mutualiteit to receive a refund of up to ~70-80%. 
                    The refund value is higher if your consultation is with your family physician. You can also opt to ‘register’ another GP as a family physician 
                    if you choose to, or ‘change’ your current family physician.
                </p>

                <p>
                    The prescribed medicines can be bought at pharmacies. The invoice from pharmacies can also be submitted for refund from the mutualiteit. 
                    A portion of the invoice will be refunded depending on the type of medication and the illness for which it is prescribed.  
                </p>
                <p>
                    Common medicines such as cold medicines, paracetamol etc can be bought from pharmacies without prescription. 
                    There is always one pharmacy that is open at any time, even during the weekend. This role is rotated among the pharmacies in the city. 
                    You can see which pharmacy is open on a particular day during unconventional hours or weekends by consulting <a href="https://www.apotheek.be/" target="_blank" >apotheek.be</a> . 
                    The info is also available as a poster on any pharmacy and will be visible even if that particular pharmacy is not open. 
                </p>
                <p>
                    Similarly, there are always doctors who are available for emergencies. 
                    You can call 1733 to make an appointment, and you will be told where you can go. 
                    In the worst case you can go to the emergency ward in one of the hospitals. 
                </p>
            </mat-expansion-panel>
        </mat-accordion>
    <app-layered-waves></app-layered-waves>
</div>