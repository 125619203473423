import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Event } from 'src/app/shared/model/event';
import { EventManagementService } from 'src/app/shared/services/event-management.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
  private _event!: Event;

  constructor(
    private route: ActivatedRoute,
    private eventManagementService: EventManagementService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.setEventWithId(+params['id']);
      },
      (err) =>
        console.log(
          'Wrong route! No event exists for specified id in the url!\n' + err
        )

      // TODO: err => throw new
      // Throw error because no event was found with specified id (the route is incorrect!)
    );
  }

  get event(): Event {
    return this._event;
  }

  set event(value: Event) {
    this._event = value;
  }

  /* Set the event property of this component to the event fetched from backend with given id */
  setEventWithId(id: number): void {
    this.eventManagementService
      .getById(id.toString())
      .subscribe((event: Event) => {
        this.event = event;
      });
  }

  onEdit() {
    this.router.navigate(['edit'], { relativeTo: this.route });
  }
}
