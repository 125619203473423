<article class="main-content">
    <header class="entry-header">
        <h1 class="entry-title">Join us</h1>
    </header>
    <div class="entry-content">
        <p>The members of IMSAL are our core driving force, and make the organisation everything it is. Members also
            enjoy
            exclusive benefits such as the provision to book rooms in mosque building for events, study sessions, etc.
        </p>
        <p>To avail the perks of being an IMSAL member and join our mailing lists, please fill in the form <a
                href="login">here</a>.</p>
        <p>Want to stay in the loop but do not want to become a member? Please navigate to this page and fill in the
            <a>form</a>.
        </p>
    </div>
</article>