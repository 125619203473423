import {
    trigger,
    state,
    style,
    transition,
    animate,
    keyframes,
    animation,
} from "@angular/animations";
import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { HomeFirstSectionComponent } from "./home-first-section/home-first-section.component";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
    animations: [
        trigger("slideIn", [
            transition("void => *", [
                style({ opacity: 0, transform: "translateX(-30%)" }), // Initial state
                animate(
                    "1000ms linear",
                    keyframes([
                        style({
                            opacity: 0,
                            transform: "translateX(-30%)",
                            offset: 0,
                        }), // Fade in from left
                        style({
                            opacity: 1,
                            transform: "translateX(0)",
                            offset: 1,
                        }), // Slide in and fully fade in
                    ])
                ),
                // Remove the 'hidden' class after the animation starts
                // animate(0, style({ display: 'block' })),
            ]),
        ]),
    ],
})
export class HomeComponent implements OnInit {
    @ViewChildren("sections") sections?: QueryList<ElementRef>;

    sectionVisibility = [
        { showH1: false, showP: false },
        { showH1: false, showP: false },
        { showH1: false, showP: false },
        // Add more sections as needed
    ];

    showSection(index: number) {
        this.sectionVisibility[index].showH1 = true;
        setTimeout(() => (this.sectionVisibility[index].showP = true), 500); // Delay showing the <p> element by 500ms
    }

    eventImagesInfo = [
        {
            url: "./assets/images/events-preview/welcome-event.jpg",
            show: false,
            title: "Welcome events",
        },
        {
            url: "./assets/images/events-preview/eid-prayer.JPG",
            show: false,
            title: "Eid Prayer",
        },
        {
            url: "./assets/images/events-preview/nature-hike.jpg",
            show: false,
            title: "Nature Hike",
        },
        {
            url: "./assets/images/events-preview/football.jpg",
            show: false,
            title: "Football",
        },
        {
            url: "./assets/images/events-preview/eid-bbq.jpg",
            show: false,
            title: "Eid BBQ",
        },
    ];
    // TODO
    showEventPictures() {
        for (let i = 0; i < this.eventImagesInfo.length; i++) {
            setTimeout(
                () => (this.eventImagesInfo[i].show = true),
                (i + 1) * 700
            );
        }
    }

    constructor() {}

    ngOnInit(): void {}
}
