import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";

@Component({
    selector: "app-prayer-times",
    templateUrl: "./prayer-times.component.html",
    styleUrls: ["./prayer-times.component.scss"],
})
export class PrayerTimesComponent {
    constructor(public dialog: MatDialog) {}

    get tablePath(): string {
        return (
            "./assets/images/prayer-times/CMB-" +
            ("0" + (new Date().getMonth() + 1)).slice(-2) +
            "-2024.png"
        );
    }

    openCMBInfoDialog() {
        this.dialog.open(CMBInfoDialog);
    }
}

@Component({
    selector: "cmb-info-dialog",
    template: ` <h2 mat-dialog-title>Where did the EMB prayer times go?</h2>
        <mat-dialog-content class="mat-typography">
            On September 15, 2022, the minister withdrew the recognition of the
            EMB by the state, and their website became inactive.
            <br />
            <br />
            A few months later, another project got selected as a new Executive
            of Muslims in Belgium: the “Muslim Council of Belgium” (CMB). Since
            then, they are in charge of all the executive tasks.
            <br />
            <br />
            <b>However</b>, the prayer times remain unchanged. It is still the
            same Council of Theologians of Belgium (which consists of renowned
            scholars) that is in charge of it. The CMB or EMB only publish the
            time-sheets.
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Close</button>
        </mat-dialog-actions>`,
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
})
export class CMBInfoDialog {}
