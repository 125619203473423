<div [@destroyAndReveal]>
    <header class="entry-header">
        <h1>List Of Members</h1>
    </header>
    <mat-tab-group class="entry-content" mat-align-tabs="center" mat-stretch-tabs>
        <mat-tab *ngFor="let category of categories" label={{category}}>
            <div class="grid">
                <mat-card *ngFor="let user of users|ofCategory:category;index as i" (mouseover)="hoverOn(i)"
                    [@onHover]="currentState[i]" (mouseout)="hoverOff(i)" mat-raised-button matTooltip="Show more"
                    (click)="openDialog(user)" matTooltipPosition="above" class="user-card">
                    <mat-card-header>
                        <mat-card-title>Name:{{user.name}}</mat-card-title>
                    </mat-card-header>
                    <img class="profile" mat-card-sm-image src={{user.profile}} />
                    <mat-card-content>
                        <mat-card-subtitle>Address: {{user.address}}</mat-card-subtitle>
                        <mat-card-subtitle>Phone: {{user.phone}}</mat-card-subtitle>
                        <h4>About {{user.name}} :</h4>
                        <p>{{user.about | truncatePipe:6:'....'}} </p>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>