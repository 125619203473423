<div class="row">
    <div class="col-xs-12">
      <img
        [src]="event.imagePath"
        alt="{{ event.title }}"
        class="img-responsive"
        style="max-height: 300px;">
    </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <div class="row">
      <h1>{{ event.title }}</h1>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    {{ event.description }}
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <!--Something Else HERE !!-->
  </div>
</div>
<div class="row">
  <button
    type="button"
    class="btn btn-primary"
    (click)="onToEventDetails()">
    To Event Details
  </button>
</div>

<!--TODO: give a maximum height for the events zoom (probably in the list component)-->