import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/model/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  successLogin: boolean = false;
  dangerLogin: boolean = false;

  loginUserData = {
    email: '',
    password: '',
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  loginUser() {
    let user: User = {
      email: this.loginUserData.email,
      password: this.loginUserData.password,
    };

    this.authenticationService.loginUser(user).subscribe(
      (res) => {
        console.log(res);
        if (res == true) {
          this.successLogin = true;
          this.dangerLogin = false;
        } else {
          this.dangerLogin = true;
          this.successLogin = false;
        }
      },
      (err) => {
        console.log(err);
        this.dangerLogin = true;
        this.successLogin = false;
      }
    );
  }
}
