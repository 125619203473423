import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Input } from "@angular/core";

@Component({
    selector: "app-carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit, AfterViewInit {
    figure: any;
    selectedIndex = 0;
    interval: any;
    slideIntervalManager(start: boolean): void {
        if (start) {
            this.interval = setInterval(() => {
                let next = this.selectedIndex + 1;
                let initial = 0;
                this.selectedIndex < this.images.length - 1
                    ? this.selectImage(next)
                    : this.selectImage(initial);
            }, 5000);
        } else {
            clearInterval(this.interval);
        }
    }
    //select image click event
    clickImage(next: number) {
        this.slideIntervalManager(false);
        this.selectImage(next);
        if (this.autoSlide === true) {
            this.slideIntervalManager(true);
        }
    }
    selectImage(next: number) {
        let prev = this.selectedIndex;
        this.selectedIndex = next;
        for (let i = 0; i < next; i++) {
            if (i != prev) {
                this.figure[i].setAttribute("style", "opacity:0;left:-100%");
            }
        }
        for (let i = next + 1; i < this.figure.length; i++) {
            if (i != prev) {
                this.figure[i].setAttribute("style", "opacity:0;left:100%");
            }
        }
        if (prev > next) {
            this.figure[prev].setAttribute(
                "style",
                "opacity:1;position:absolute;left:100%"
            );
        } else if (prev < next) {
            this.figure[prev].setAttribute(
                "style",
                "opacity:1;position:absolute;left:-100%"
            );
        }
        this.figure[next].setAttribute(
            "style",
            "opacity:1;position:asbolute;left:0"
        );
    }

    @Input() indicators = true;

    @Input() autoSlide = false;
    images = [
        {
            url: "./assets/images/slider/Welcome Event Series - front.png",
        },
        {
            url: "./assets/images/slider/walk-tour.jpg",
        },
        {
            url: "./assets/images/slider/potluck.png",
        },
        {
            url: "./assets/images/slider/BBQ.png",
        },
        {
            url: "./assets/images/slider/Welcome Event Series - back.png",
        },
    ];
    constructor() {}
    ngOnInit(): void {
        if (this.autoSlide === true) {
            this.slideIntervalManager(this.autoSlide);
        }
    }
    ngAfterViewInit(): void {
        this.figure = document.querySelectorAll("figure");
    }
}
