<section id="heading">
    <app-header-waves></app-header-waves>
    <div id="text-and-logo">
        <div class="section">
            <div id="inner-flex">
                <h1><span id="typed-text">Welcome to the</span><div id="cursor"></div></h1>
                <h2>International <br> Muslim <br> Students' <br> Association of <br> Leuven</h2>
                <p>Where we reflect, both spiritually and intellectually, for a better future</p>
            </div>
        </div>
        <div class="section">
            <img id="logo" src="./assets/images/logos/imsal/IMSAL-LOGO.png" alt="">
        </div>
    </div>
</section>
<app-layered-waves></app-layered-waves>