import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./authentication/login/login.component";
import { EventsMakerComponent } from "src/app/events/events-maker/events-maker.component";
import { EventEditComponent } from "src/app/events/event-edit/event-edit.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { EventsComponent } from "src/app/events/events.component";
import { EventListComponent } from "src/app//events/event-list/event-list.component";
import { EventDetailComponent } from "src/app/events/event-detail/event-detail.component";
import { EventCalendarComponent } from "src/app/events/event-calendar/event-calendar.component";
import { HomeComponent } from "./home/home.component";
import { JoinUsComponent } from "./join-us/join-us.component";
import { AboutPageComponent } from "./about-page/about-page.component";
import { ContactUsPageComponent } from "./contact-us-page/contact-us-page.component";
import { FaqPageComponent } from "./faq-page/faq-page.component";
import { MembersComponent } from "./members/members.component";
import { PrayerTimesComponent } from "./prayer/prayer-times/prayer-times.component";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
    },
    {
        path: "members",
        component: MembersComponent,
    },
    {
        path: "joinUs",
        component: JoinUsComponent,
    },
    {
        path: "about",
        component: AboutPageComponent,
    },
    {
        path: "contact",
        component: ContactUsPageComponent,
    },
    {
        path: "faq",
        component: FaqPageComponent,
    },
    {
        path: "islamic-living/prayertimes",
        component: PrayerTimesComponent,
    },
    {
        path: "events",
        component: EventsComponent,
        children: [
            {
                path: "",
                component: EventCalendarComponent,
            },
            {
                path: "list",
                component: EventListComponent,
            },
            {
                path: "new",
                component: EventsMakerComponent,
            },
            {
                path: ":id",
                component: EventDetailComponent,
            },
            {
                path: ":id/edit",
                component: EventEditComponent,
            },
        ],
    },
    // {
    //   path: 'gallery',
    //   component: GalleryPageComponent,
    // },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "not-found",
        component: PageNotFoundComponent,
    },
    {
        path: "**",
        redirectTo: "/not-found",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
